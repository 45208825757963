// 公共文件-判断当前client的mec页面有没有selector功能
// let showSelector = false;
// let client = localStorage.getItem("client");
// if (client == 'adobe' || client == 'NatWest' || client == 'HPE Campus' || client == 'Clorox' || client == 'WarnerMedia' || client == 'CX Campus' || client == 'KLA' || client == 'DoorDash' || client == 'IMD Campus' || client == 'Metlife' || client == 'Rivian'|| client == 'Cloudflare'|| client == 'Pepsico') {
//   showSelector = true
// }
// export default showSelector

let showSelector = true;
// let client = localStorage.getItem("client");
// if (client == 'adobe' || client == 'NatWest' || client == 'HPE Campus' || client == 'Clorox' || client == 'WarnerMedia' || client == 'CX Campus' || client == 'KLA' || client == 'DoorDash' || client == 'IMD Campus' || client == 'Metlife' || client == 'Rivian'|| client == 'Cloudflare'|| client == 'Pepsico') {
//   showSelector = false
// }
export default showSelector
